const restaurant = {
  restaurantInfo: {
    restaurantInfo: {
      code: 0,
      result: {}
    },
  }
};

export default restaurant;
